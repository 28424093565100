import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { Layout } from '$components';
import NextPrevious from '../components/NextPrevious';
import config from '../../config';
import { StyledHeading, StyledMainWrapper } from '../components/styles/Docs';
import { getCurrentLangKey } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';

const forcedNavOrder = config.sidebar.forcedNavOrder;

export default class MDXRuntimeTest extends Component {
  render() {
    const { data, location } = this.props;

    if (!data) {
      return this.props.children;
    }
    const {
      allMdx,
      mdx,
      site: {
        siteMetadata: {
          docsLocation,
          title,
          languages: { langs, defaultLangKey },
        },
      },
    } = data;
    const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname);

    const navItems = allMdx.edges
      .map(({ node }) => node.fields.slug)
      .filter((slug) => {
        return !['/', '/en'].includes(slug);
      })
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find((url) => url === cur)) {
            return { ...acc, [cur]: [cur] };
          }

          let prefix = cur.split('/')[1];

          if (config.gatsby && config.gatsby.trailingSlash) {
            prefix = prefix + '/';
          }

          if (prefix && forcedNavOrder.find((url) => url === `/${prefix}`)) {
            return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
          } else {
            return { ...acc, items: [...acc.items, cur] };
          }
        },
        { items: [] }
      );

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur]);
      }, [])
      .concat(navItems.items)
      .map((slug) => {
        if (slug) {
          const { node } = allMdx.edges.find(({ node }) => node.fields.slug === slug);

          return { title: node.fields.title, url: node.fields.slug };
        }
      });

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle;

    const metaDescription = mdx.frontmatter.metaDescription;

    let canonicalUrl = config.gatsby.siteUrl;

    canonicalUrl =
      config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;
    canonicalUrl = canonicalUrl + mdx.fields.slug;

    return (
      <IntlProvider locale={langKey}>
        <Layout {...this.props}>
          <Helmet>
            {metaTitle ? <title>{metaTitle}</title> : null}
            {metaTitle ? <meta name="title" content={metaTitle} /> : null}
            {metaDescription ? <meta name="description" content={metaDescription} /> : null}
            {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
            {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
            {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
            {metaDescription ? (
              <meta property="twitter:description" content={metaDescription} />
            ) : null}
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
          <div className={'titleWrapper'}>
            <StyledHeading>{mdx.fields.title}</StyledHeading>
          </div>
          <StyledMainWrapper>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </StyledMainWrapper>
          <div className={'addPaddTopBottom'}>
            <NextPrevious mdx={mdx} nav={nav} />
          </div>
        </Layout>
      </IntlProvider>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
        languages {
          langs
          defaultLangKey
        }
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx(sort: { fields: frontmatter___stt, order: ASC }) {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
