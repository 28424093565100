import * as React from 'react';
import Tree from './tree';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { getCurrentLangKey } from 'ptz-i18n';

const Sidebar = styled('aside')`
  width: 100%;
  height: 100vh;
  position: fixed;
  padding-left: 0px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: sticky;
  top: 0;
  padding-right: 0;
  overflow-y: scroll;
  background-color: #f3f5f7;

  ::-webkit-scrollbar {
    width: 7px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #ccc;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 100vh;
  }

  @media (min-width: 767px) and (max-width: 1023px) {
    padding-left: 0;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    height: auto;
  }
`;

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(sort: { fields: frontmatter___stt, order: ASC }) {
          edges {
            node {
              fields {
                slug
                title
              }
              frontmatter {
                stt
              }
            }
          }
        }
        site {
          siteMetadata {
            languages {
              langs
              defaultLangKey
            }
          }
        }
      }
    `}
    render={({ allMdx, site }) => {
      const { langs, defaultLangKey } = site.siteMetadata.languages;
      const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname);

      return (
        <Sidebar>
          <ul className={'sideBarUL'}>
            <Tree
              edges={allMdx.edges.filter((e) =>
                langKey === 'en'
                  ? e.node.fields.slug.startsWith('/en')
                  : !e.node.fields.slug.startsWith('/en')
              )}
            />
          </ul>
        </Sidebar>
      );
    }}
  />
);

export default SidebarLayout;
