import React from 'react';

function RunInPostmanButton() {
  return (
    <div style={{ marginBottom: '2rem' }}>
      <span style={{ verticalAlign: 'middle', fontWeight: 500, marginRight: 16 }}>
        Thử nghiệm nhanh API:
      </span>
      <a
        href="https://www.postman.com/cvsapi/workspace/computer-vision-vietnam/collection/12361979-3a86208f-50df-4e28-a32b-f4e055d77a8d?tab=variables&ctx=documentation"
        target="_blank"
        style={{ verticalAlign: 'middle', display: 'inline-flex' }}
      >
        <img src="https://run.pstmn.io/button.svg" alt="Run in Postman" />
      </a>
    </div>
  );
}

export default RunInPostmanButton;
