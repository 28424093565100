import React from 'react';
import styled from 'styled-components';

function Note({ children }) {
  const Note = styled.div`
    background-color: #faf8ff;
    color: #0f0a1f;
    border-radius: 0;
    border: none;
    border-left: 2px solid #9c5f99;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
  `;
  const NoteBody = styled.div`
    font-weight: 500;
    font-size: 0.85em;
    color: #344658;
  `;
  return (
    <Note role="alert">
      <NoteBody>{children}</NoteBody>
    </Note>
  );
}

export default Note;
