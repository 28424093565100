const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://computervision.com.vn/',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    title: `API document by Computer Vision Vietnam`,
    social: `<li>
		    <a href="https://www.facebook.com/computervisionvietnam/" target="_blank" rel="noopener"></a>
		  </li>
			`,
    links: [{ text: '', link: '' }],
  },
  sidebar: {
    forcedNavOrder: [
      '/huong-dan-su-dung-dashboard',
      '/co-che-xac-thuc',
      '/v3',
      '/v2',
      '/en/authentication-mechanism',
      '/en/v3',
      '/en/v2',
    ],
    collapsedNav: [
      '/index',
      '/v3',
      '/en/index',
      '/en/v3',
    ],
    links: [
      {
        text: 'Hướng dẫn sử dụng Dashboard',
        link: 'https://drive.google.com/file/d/1p0GHrIXJ_dHXJMrIFMe7embYzlGgr9ed/view',
      },
    ],
    frontLine: false,
    ignoreIndex: true,
    title: "<a href='/'>Danh mục</a>",
  },
  siteMetadata: {
    title: 'API document by Computer Vision Vietnam',
    description: 'API document by Computer Vision Vietnam',
    ogImage: null,
    docsLocation: '',
    languages: {
      langs: ['vi', 'en'],
      defaultLangKey: 'vi'
    }
  },
  pwa: {
    enabled: true, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Document',
      short_name: 'Document',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
      icon: 'src/components/images/cvs-favicon.png',
    },
  },
};

module.exports = config;
