import * as React from 'react';
import styled from '@emotion/styled';
import Link from './link';
import LogoCvsSvg from './images/logo-cvs';

import Sidebar from './sidebar';

function myFunction() {
  var x = document.getElementById('navbar');

  if (x.className === 'topnav') {
    x.className += ' responsive';
  } else {
    x.className = 'topnav';
  }
}

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${(props) => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const BgNavDefault = styled('nav')`
  background: ${(props) => (props.isDarkThemeActive ? 'rgb(20, 20, 20)' : undefined)};
`;

const Header = ({ location }) => {
  return (
    <div className={'navBarWrapper'}>
      <BgNavDefault className={'navBarDefault'}>
        <div className={'navBarHeader'}>
          <Link to="/" className={'navBarBrand'}>
            <LogoCvsSvg />
          </Link>
        </div>

        <div id="navbar" className={'topnav'}>
          <div className={'visibleMobile'}>
            <Sidebar location={location} />
            <hr />
          </div>
        </div>
      </BgNavDefault>
      <StyledBgDiv>
        <div className={'navBarDefault removePadd'}>
          <span
            onClick={myFunction}
            className={'navBarToggle'}
            onKeyDown={myFunction}
            role="button"
            tabIndex={0}
          >
            <span className={'iconBar'}></span>
            <span className={'iconBar'}></span>
            <span className={'iconBar'}></span>
          </span>
        </div>
      </StyledBgDiv>
    </div>
  );
};

export default Header;
